@import "global";

.embed-video {
  margin-top: spacer(section);
  .row {
    &:not(:first-child) {
      margin-top: 80px;
      @include breakpoint(phone down) {
        margin-top: 40px;
      }
    }
  }
  &__placeholder {
    position: relative;
    @include aspectRatio(0.565);
    &--vertical {
      @include aspectRatio(1.265);
    }
  }
  &__video {
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  &__iframe {
    @include position(absolute, 0);
    @include size(100%);
    &--full {
      &::after {
        background-color: rgba($cBlack, 0.5);
        content: '';
        width: 100%;
        padding-bottom: 56.25%;
        position: absolute;
        top: 0;
        display: block;
      }
    }
    &--full2 {
      &::after {
        background-color: rgba($cBlack, 0.7);
        content: '';
        width: 100%;
        padding-bottom: 56.25%;
        position: absolute;
        top: 0;
        display: block;
      }
    }
  }
}